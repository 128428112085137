import React from "react";
import Footer from "./footer";

function newsletter() {
window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  return (
    <div className="bg-black font-defmono text-white grid items-center w-full overflow-x-clip ">
     <div className="w-full h-screen">
     <h1 className="text-center text-5xl tablet:text-8xl pt-20 underline decoration-1 tablet:underline-offset-[12px] underline-offset-8 pb-8">
        NEWSLETTER
      </h1>
      <div className="w-full mobile:h-auto pc:h-screen  text-center text-2xl">
        <p>
          Unleash your creative genius in the biggest photography competition in CUSAT!
        </p>
        <p>
          Sign up right now and submit your entries to win cool prizes, goodies and much more!
        </p>
        <p>
        <a
                href="https://forms.gle/9qNbbHXWMbZ2vRVY7"
                className="inline-block rounded-lg mt-10 w-72 bg-opacity-60 hover:bg-opacity-100  cursor-pointer bg-gray-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-gray-600 hover:bg-gray-700 hover:ring-gray-700"
              >
                <p>Register now!</p>
              </a>
        </p>
      </div>
     </div>
     <Footer/>
    </div>
    
  );
}

export default newsletter;
